import React, { useState, useEffect } from 'react';
import handleViewport from 'react-in-viewport';

const EmptyComponent = () => {
    const emptyStyle = {
        height: '100%',
    };
    return <div style={emptyStyle}></div>;
};

const LazyLoadComponent = ({ inViewport, forwardedRef, component: Component, ...rest }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (inViewport && !isLoaded) {
            setIsLoaded(true);
        }
    }, [inViewport, isLoaded]);


    return (
        <div ref={forwardedRef} >
            {isLoaded ? <Component {...rest} /> : <EmptyComponent />}
        </div>
    );
};

const LazyLoad = handleViewport(LazyLoadComponent, { rootMargin: '200px' });

export default LazyLoad;
