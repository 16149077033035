import { Stack, Typography } from '@mui/material';
import DefaultPaper from "./DefaultPaper";
import { Vega } from 'react-vega';

const NotFoundComponent = () => {

    const spec = require('specs/not-found.json')

    return (
        <DefaultPaper>
            <Stack direction="column" spacing={3}>
                <Typography variant="h4" align='center'>This page could not be found</Typography>
                <Vega spec={spec}/>
            </Stack>
        </DefaultPaper>
    )
}

export default NotFoundComponent