import { Box } from "@mui/material";

const EnvironmentBadge = () => {
    const env = window.REACT_APP_ENV;

    const shouldShowBadge = () => {
        return !env.includes('prod');
    }

    const readableName = (envString) => {
        if (envString.includes("dev")) {
            return "DEV"
        }
        if (envString === "kws_stage" || envString === "matrix") {
            return "STAGE"
        }
        return envString
    }

    return (
        shouldShowBadge() &&
        <Box
            sx={{
                position: 'fixed',
                top: 30,
                left: -40,
                transform: 'rotate(-45deg)',
                transformOrigin: 'top left',
                backgroundColor: 'yellow',
                color: 'black',
                fontSize: '14px',
                fontWeight: 'bold',
                lineHeight: '80px',
                width: '100px',
                height: '50px',
                textAlign: 'center',
                zIndex: 1000,
            }}
        >
            {readableName(env)}
        </Box>
    );
}

export default EnvironmentBadge;