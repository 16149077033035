import { Stack, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import SingleSelectConfigList from 'components/SingleSelectConfigList';
import { ApiContext } from 'helper/ApiContext';
import Logger from 'helper/Logger';
import { useContext, useEffect, useState } from 'react';

const DTypeMappingDrawerComponent = ({
    open,
    onClose,
    filename,
    isForeignPipe
}) => {

    const { api } = useContext(ApiContext)
    const [state, setState] = useState({})

    useEffect(() => {
        if (filename !== undefined) {
            api.getFile(filename)
                .then((response) => {
                    setState(response.data.dtype_mapping.mapping)
                }).catch((error) => {
                    Logger.error(error)
                })
        }
    }, [filename])

    useEffect(() => {
        if (Object.keys(state).length > 0 && filename !== undefined) {
            api.updateFileDTypeMapping(filename, state).then(() => {
                // TODO: notify parent?
            }).catch((error) => {
                Logger.error(error)
            })
        }
    }, [state])

    return (
        <Drawer
            anchor={"right"}
            open={open}
            onClose={onClose}
        >
            <Box
                sx={{ mt: 8, width: 250 }}
                role="presentation"
            >
                <List>
                    {Object.entries(state).map(([column, selectedValue], index) => (
                        <ListItem key={index} width="300px">
                            <Stack direction="column">
                                <Typography variant="h6">{column}</Typography>
                                <SingleSelectConfigList
                                    config={{ options: ["Number", "Date", "Text"], selected_values: [selectedValue] }}
                                    onSelectOption={(option) => setState((state) => ({ ...state, [column]: option }))}
                                    isForeignPipe={isForeignPipe}
                                />
                            </Stack>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    )
}

export default DTypeMappingDrawerComponent