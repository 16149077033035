import { Box, Grid } from "@mui/material";
import DefaultPaper from "components/DefaultPaper";
import BrushSelectionListPlotComponent from "components/PlotComponents/BrushSelectionListPlotComponent";
import ConfigurePlotSidebarComponent from "components/PlotComponents/ConfigurePlotSidebarComponent";
import LoadingSnackbar from "components/snackbars/LoadingSnackbar";
import { ProcessBlocks, TableBlocks } from "helper/Constants";
import Logger from "helper/Logger";
import { useIsForeignPipe } from "helper/UrlUtils";
import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../helper/ApiContext";
import DataLoadingTableComponent from "../table/DataLoadingTableComponent";
import BlockDetailsComponent from "./BlockDetailsComponent";
import FilterPageComponent from "./FilterPageComponent";
import ListFilesPageComponent from "./ListFilesPageComponent";

const BlockPageComponent = ({
    pipeId,
    nodeId,
    usernameFromPath,
    reload,
    showTable = true,
    isDashboardItem = false,
    showStaticPlot = true,
    onSelectionChanged = () => { }
}) => {

    const { api } = useContext(ApiContext);
    const [block, setBlock] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [reloadCounter, setReloadCounter] = useState(0)
    const blockType = (block) => (block?.blueprint?.type ?? "")
    const firstChildType = (block) => (block?.blueprint?.children[0]?.type ?? "")
    const isForeignPipe = useIsForeignPipe(usernameFromPath);

    // we mirror the reload flag to an internal one, because some child components need it but want to modify it here too
    useEffect(() => {
        setReloadCounter(reloadCounter + 1)
    }, [reload])

    useEffect(() => {
        if (isLoading) return
        setIsLoading(true)
        api.getBlock(pipeId, nodeId, usernameFromPath).then((response) => {
            setIsLoading(false)
            setBlock(response.data)
        }).catch((error) => {
            Logger.error("BlockPageComponent: could not get block: " + JSON.stringify(error))
        })
    }, [reloadCounter])

    const onSaveBlockConfiguration = (config) => {
        api.updateNodeConfiguration(pipeId, nodeId, usernameFromPath, config).then(() => {
            Logger.info("Successfully updated node configuration")
            setReloadCounter(reloadCounter + 1)
        }).catch((error) => {
            Logger.error(error)
        })
    }

    let content = () => {
        let type = blockType(block)
        if (type === "import") {
            return <ListFilesPageComponent
                pipeId={pipeId}
                nodeId={nodeId}
                usernameFromPath={usernameFromPath}
                showTable={showTable}
                onSelectionChanged={onSelectionChanged}
            />
        } else if (ProcessBlocks.includes(type)) {
            if (firstChildType(block) === "plot") {
                return <BrushSelectionListPlotComponent
                    pipeId={pipeId}
                    nodeId={nodeId}
                    reloadCounter={reloadCounter}
                    onSelectionChanged={onSelectionChanged}
                    showTable={showTable}
                    usernameFromPath={usernameFromPath}
                    loadStaticPlot={showStaticPlot}
                />
            } else {
                return <DataLoadingTableComponent pipeId={pipeId} nodeId={nodeId} username={usernameFromPath} reloadPipeCounter={reloadCounter} />
            }
        } else if (["pivot", "merge", "export", "breakout", "filter_orthogonal", "mean", "gca", "show_table", "relative"].includes(type)) {
            return <DataLoadingTableComponent pipeId={pipeId} nodeId={nodeId} username={usernameFromPath} reloadPipeCounter={reloadCounter} />
        } else if (["filter_categories", "create_subgroup"].includes(type)) {
            return <FilterPageComponent
                pipeId={pipeId}
                nodeId={nodeId}
                blockType={type}
                usernameFromPath={usernameFromPath}
                reload={reloadCounter}
                showTable={showTable}
                onSelectionChanged={onSelectionChanged}
            />
        } else {
            return (<div style={{ margin: 10 }}>Loading...</div>)
        }

    }

    let properlyWrappedContent = () => {
        if (isDashboardItem) {

            if (TableBlocks.includes(blockType(block))) {
                return (
                    <></>
                );
            }

            return (
                <Box sx={{ overflowY: "hidden", overflowX: "scroll" }}>
                    {content()}
                </Box>
            )
        } else {
            return (
                <DefaultPaper additionalSx={{ flexDirection: "column" }}>
                    {block &&
                        <DefaultPaper additionalSx={{ mt: 0, mb: 2 }}>
                            <BlockDetailsComponent
                                pipeId={pipeId}
                                usernameFromPath={usernameFromPath}
                                block={block}
                                isForeignPipe={isForeignPipe}
                                isOnDashboard={false}
                                onSelectionChanged={onSelectionChanged}
                            />
                        </DefaultPaper>}
                    {content()}
                </DefaultPaper>
            )
        }
    }

    let showSidebar = block && blockType(block) !== "import" && blockType(block) !== "show_table" && !isDashboardItem;
    if (showSidebar) {
        return (
            <Grid container spacing={2} sx={{ width: "97vw" }}>
                <LoadingSnackbar loading={isLoading} />
                <Grid item xs={10}>
                    {properlyWrappedContent()}
                </Grid>
                <Grid item xs={2} sx={{ maxHeight: '90px' }}>
                    {block && showTable &&
                        <ConfigurePlotSidebarComponent
                            onSaveConfig={(config) => onSaveBlockConfiguration(config)}
                            isForeignPipe={isForeignPipe}
                            initialConfig={block.configuration}
                        />}
                </Grid>
            </Grid>
        )
    } else {
        return properlyWrappedContent()
    }
}

export default BlockPageComponent