import { createTheme } from "@mui/material";
import { blue } from '@mui/material/colors';

const loadTheme = (darkMode) => {
    const palette = {
        main: blue[700],
        accent: '#272A2A',
        light: '#c8d0d6',
        dark: '#5f696e'
    }

    const text = {
        title: {
            main: "NoMaze"
        }
    }

    const img = {
        logo: {
            main: "logo full white.png"
        },
        favicon: {
            main: "favicon.ico"
        }
    }

    // override single color values
    if (window.REACT_APP_ENV.includes("kws")) {
        palette.accent = '#ff5b1f'
        text.title.main = "SmartPlot"
        img.logo.main = "smartplot.png"
        img.favicon.main = "smartplot_favicon.ico"
    }

    return createTheme({
        palette: {
            primary: palette,
            mode: darkMode ? 'dark' : 'light'
        },
        text: text,
        img: img,
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => ({
                        // backgroundColor: theme.palette.primary.light,
                        boxShadow: 'none',
                        border: '1px solid black',
                    })
                }
            }
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,

                onecol: 1,
                twocol: 800,
                threecol: 1100,
                fourcol: 1300,
                fivecol: 1500,
                sixcol: 2000,

                twocol_small: 400,
                threecol_small: 600,
                fourcol_small: 800,
                fivecol_small: 1200,
                sixcol_small: 1400
            },
        },
    });
}

export default loadTheme;