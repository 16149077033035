import { FilterList } from "@mui/icons-material";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

const FilterMinMaxComponent = ({
    columnName,
    column,
    userInput,
    filterChanged,
    isForeignPipe
}) => {

    const [minValue, setMinValue] = useState(userInput.min ?? column.min)
    const [maxValue, setMaxValue] = useState(userInput.max ?? column.max)

    useEffect(() => {
        setMinValue(userInput.min)
        setMaxValue(userInput.max)
    }, [userInput])

    return (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <FilterList />
                }
                title={columnName}
            />
            <Divider />
            <CardContent sx={{ padding: 0 }}>
                <List dense>
                    <Stack direction="row">
                        <ListItem>
                            <TextField
                                id="outlined-required"
                                label="Min"
                                value={minValue}
                                onChange={(event) => { setMinValue(event.target.value) }}
                                disabled={isForeignPipe()}
                            />
                        </ListItem>
                        <Typography sx={{ alignContent: "center", whiteSpace: 'nowrap', mr: 2 }}>({Number(column.min).toFixed(2)})</Typography>
                    </Stack>
                    <Stack direction="row">
                        <ListItem>
                            <TextField
                                id="outlined-required"
                                label="Max"
                                value={maxValue}
                                onChange={(event) => { setMaxValue(event.target.value) }}
                                disabled={isForeignPipe()}
                            />
                        </ListItem>
                        <Typography sx={{ alignContent: "center", whiteSpace: 'nowrap', mr: 2 }}>({Number(column.max).toFixed(2)})</Typography>
                    </Stack>
                </List>
                <Button
                    variant="contained"
                    onClick={() => {
                        filterChanged(minValue, maxValue)
                    }}
                    disabled={isForeignPipe()}
                    sx={{ ml: 2, mt: 1, mb: 0 }}
                >
                    Save
                </Button>
            </CardContent>
        </Card>
    )
}

export default FilterMinMaxComponent;