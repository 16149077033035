import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import List from '@mui/material/List';
import { ConfigurePlotElementHeader } from "./PlotComponents/ConfigurePlotSidebarComponent";

export const MultiSelectConfigListItem = ({ option, onClick, checked, isForeignPipe = false }) => {
    return (
        <ListItemButton
            dense
            onClick={onClick}
            disabled={isForeignPipe()}
            sx={{ ml: -2, wordWrap: 'break-word', width: '120%' }}
        >
            <ListItemIcon
                style={{ height: 20, minWidth: 20 }}
                sx={{ ml: -2 }}
            >
                <Checkbox
                    checked={checked}
                    disableRipple
                />
            </ListItemIcon>
            <ListItemText
                style={{ margin: 0 }}
                primary={<Typography variant='body2'>{option}</Typography>}
            />
        </ListItemButton>
    )
}

const MultiSelectConfigList = ({ config, onSelectOption, isForeignPipe, onChangeIsSynced }) => {

    const invalidOptions = config.selected_values.filter((value) => !config.options?.includes(value))

    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <ConfigurePlotElementHeader config={config} onChangeIsSynced={onChangeIsSynced} />
            </AccordionSummary>
            <AccordionDetails>
                <List
                    dense
                    sx={{ mt: -3 }}
                >
                    {config.options?.map((option, index) => (
                        <MultiSelectConfigListItem
                            option={option}
                            onClick={() => { onSelectOption(option) }}
                            checked={config.selected_values.includes(option)}
                            isForeignPipe={isForeignPipe}
                            key={index}
                        />
                    ))}
                    {invalidOptions.length > 0 && <>
                        <Typography variant="h6" color="error" sx={{ mt: 2 }}>Invalid options</Typography>
                        {invalidOptions.map((option, index) => (
                            <MultiSelectConfigListItem
                                option={option}
                                onClick={() => { onSelectOption(option) }}
                                checked={config.selected_values.includes(option)}
                                isForeignPipe={isForeignPipe}
                                key={index}
                            />
                        ))}
                    </>}
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

export default MultiSelectConfigList;