import { AccountCircle, SaveAs, Settings, ZoomInMap } from "@mui/icons-material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Box, Button, Tooltip, useTheme } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import ProfileMenu from "components/ProfileMenu";
import ClonePipeDialogComponent from "components/dialogs/ClonePipeDialogComponent";
import { encodePipeId, useIsForeignPipe, useURLParts } from "helper/UrlUtils";
import { UserContext } from 'helper/UserContext';
import useFeatureFlags from "helper/useFeatureFlags";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../helper/ApiContext";
import Logger from "../../helper/Logger";
import Route from "../../helper/Route";
import SaveSnapshotDialog from "../SaveSnapshotDialog";
import EnvironmentBadge from "./EnvironmentBadge";
import SettingsDrawerComponent from "./SettingsDrawerComponent";


export const ToolbarBaseComponent = ({ handleLogoClick, children }) => {
    const theme = useTheme();

    return (
        <MuiAppBar
            sx={{
                zIndex: 9998,
                background: theme.palette.primary.accent,
            }}
            style={{
                borderLeft: 'none',
                borderRight: 'none',
                borderTop: 'none',
            }}
        >
            <EnvironmentBadge />
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <Box p={1}>
                    <Button onClick={handleLogoClick}>
                        <img src={theme.img.logo.main} height={35} />
                    </Button>
                </Box>

                {children}

            </Toolbar>
        </MuiAppBar>
    )
}

const ToolbarComponent = () => {
    const { api } = useContext(ApiContext);
    const { user } = useContext(UserContext)
    const { pipeId, nodeId, username, isDashboard, isTemplate } = useURLParts()
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null)
    const [saveAsDialogOpen, setSaveAsDialogOpen] = useState(false)
    const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const { currentUserIsAdmin } = useFeatureFlags()

    const shouldShowBackToPipeButton = (isDashboard === true) || nodeId !== undefined
    const shouldShowBackToDashboardButton = isDashboard === false
    const shouldShowButtonPortal = pipeId !== undefined && !isNaN(pipeId) && nodeId !== undefined && !isNaN(nodeId)
    const shouldShowExportButtonPortal = isDashboard
    const isForeignPipe = useIsForeignPipe(username);
    const shouldShowClonePipeButton = isForeignPipe() && pipeId !== undefined && !isNaN(pipeId)

    const handleSaveAsClick = (name, description) => {
        if (isNaN(pipeId)) {
            return;
        }
        api.savePipeSnapshot(pipeId, name, description)
            .then((response) => {
                // TODO: show success alert
                Logger.debug("Saved pipe snapshot: " + response.data)
            }).catch((error) => {
                // TODO: show error alert
                Logger.error("Could not save pipe snapshot: " + JSON.stringify(error))
            })
    }

    const PipeOrDashboardButton = ({ pipeId, destination }) => {
        const pipeUrl = encodePipeId(username, pipeId)
        return (
            <Tooltip title={`Switch to ${destination}`}>
                <IconButton
                    color="inherit"
                    onClick={() => {
                        navigate(`/${destination}/${pipeUrl}`)
                    }}
                >
                    {destination === "dashboard" ? <DashboardIcon /> : <ZoomInMap />}
                </IconButton>
            </Tooltip>
        )
    }

    const handleLogoClick = () => {
        navigate(Route.Home)
    }

    const handleCloneClick = () => {
        setDialogOpen(true);
    }

    return (
        <ToolbarBaseComponent handleLogoClick={handleLogoClick}>
            {shouldShowBackToPipeButton &&
                <PipeOrDashboardButton pipeId={pipeId} destination="pipe" />}

            {shouldShowBackToDashboardButton &&
                <PipeOrDashboardButton pipeId={pipeId} destination="dashboard" />}

            <Box sx={{ flexGrow: 1 }} />

            {/* ------- Space ------ */}
            <div id="toolbar-button-portal" style={shouldShowButtonPortal ? {} : { display: 'none' }}></div>
            <div id="toolbar-export-button-portal" style={shouldShowExportButtonPortal ? {} : { display: 'none' }}></div>

            {process.env.REACT_APP_ENABLE_SNAPSHOT === "true" &&
                <Tooltip title="Save Snapshot">
                    <IconButton
                        color="inherit"
                        onClick={() => {
                            setSaveAsDialogOpen(true)
                        }}
                    >
                        <SaveAs />
                    </IconButton>
                </Tooltip>}
            {shouldShowClonePipeButton &&
                <Tooltip title="Clone Pipe">
                    <IconButton
                        color="inherit"
                        onClick={handleCloneClick}
                    >
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>}
            <Tooltip title="Show Settings">
                <IconButton
                    color="inherit"
                    onClick={() => {
                        setSettingsDrawerOpen(!settingsDrawerOpen)
                    }}
                >
                    <Settings />
                </IconButton>
            </Tooltip>
            <Tooltip title="Profile">
                <IconButton
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget)
                    }}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
            </Tooltip>

            {/* ------ Popups and slideovers ------ */}
            <SaveSnapshotDialog
                open={saveAsDialogOpen}
                onClose={() => setSaveAsDialogOpen(false)}
                onSave={handleSaveAsClick}
            />
            <SettingsDrawerComponent
                open={settingsDrawerOpen}
                onClose={() => setSettingsDrawerOpen(false)}
                pipeId={pipeId}
                usernameFromPath={username}
            />
            <ProfileMenu anchor={anchorEl} setAnchor={setAnchorEl} />
            <ClonePipeDialogComponent
                open={dialogOpen}
                setOpen={setDialogOpen}
                usernameFromPath={username}
                pipeId={pipeId}
                isTemplate={isTemplate}
            />

        </ToolbarBaseComponent>
    )
}

export default ToolbarComponent;