import {Vega} from "react-vega";
import * as React from "react";
import {Paper} from "@mui/material";

const DemoPageComponent = () => {

    const spec = require("../../specs/multi-small.vg.json")

    return (
        <Paper sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'row',
        }}>
            <Vega
                spec={spec}
            ></Vega>
        </Paper>
    )
}

export default DemoPageComponent