import {Paper} from "@mui/material";

const DefaultPaper = ({additionalSx, children}) => {
    return (
        <Paper sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'row',
            mt: 7,
            overflow: 'auto',
            ...additionalSx
        }}>
            {children}
        </Paper>
    )
}

export default DefaultPaper