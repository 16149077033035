import {useContext, useEffect, useState} from "react";
import {ApiContext} from "../../helper/ApiContext";
import {UserContext} from "../../helper/UserContext";
import Logger from "../../helper/Logger";
import {Box, Grid, Stack, IconButton, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import {PipeItemComponent} from "../PipeItemComponent";
import {useNavigate} from "react-router-dom";
import Route from "../../helper/Route";
import UserPermission from "../../helper/UserPermission";
import AppsIcon from '@mui/icons-material/Apps';
import DefaultPaper from "components/DefaultPaper";
import { encodePipeId } from "helper/UrlUtils";

const PipesWidgetComponent = () => {
    const {api} = useContext(ApiContext);
    const {user} = useContext(UserContext);
    const navigate = useNavigate();
    const [pipes, setPipes] = useState([]);
    const isAdmin = user?.permissions.some((p) => p.name === UserPermission.ADMIN)

    useEffect(() => {
        api.getUserPipes()
            .then((response) => {
                setPipes(response.data)
            })
            .catch((error) => {
                Logger.error("Could not get user pipes: " + JSON.stringify(error))
            })
    }, [user])

    const handlePipeClick = (pipe) => {
        const pipeUrl = encodePipeId(user.username, pipe.pipe_id)
        if (isAdmin) {
            navigate(Route.Pipe + "/" + pipeUrl)
        } else {
            navigate(Route.Dashboard + "/" + pipeUrl)
        }
    }

    const handleMainClick = () => {
        navigate(Route.Pipes + "/" + user.username)
    }

    return (
        <DefaultPaper additionalSx={{ flexDirection: "column", padding: '20px', margin: '20px', height: '100%' }}>
            <Stack  direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">My Pipes</Typography>
                <Tooltip title="Go to Pipes Page">
                    <IconButton onClick={() => handleMainClick()}>
                        <AppsIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
            <Grid container spacing={3} sx={{ mt: 2 }}>
                {pipes.slice(0, 5).map((pipe, index) => (
                <Grid item key={index}>
                    <Box>
                        <PipeItemComponent pipe={pipe} onClick={() => handlePipeClick(pipe)} />
                    </Box>
                </Grid>
            ))}
            </Grid>
        </DefaultPaper>
    )
}

export default PipesWidgetComponent