import { UserContext } from 'helper/UserContext';
import UserPermission from "helper/UserPermission";
import { useContext } from 'react';



const useFeatureFlags = () => {
    const { user } = useContext(UserContext)

    const enableLazyLoading = false
    const enableDragToReorder = false
    const hideCountColumn = user !== undefined && user.username === "tim.haban@rwa.at"
    const currentUserIsAdmin = () => (
        user !== undefined && user.permissions.some((p) => p.name === UserPermission.ADMIN)
    )


    return {
        enableLazyLoading: enableLazyLoading,
        hideCountColumn: hideCountColumn,
        enableDragToReorder: enableDragToReorder,
        currentUserIsAdmin: currentUserIsAdmin
    }
}

export default useFeatureFlags;