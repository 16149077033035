import { MenuItem } from "@mui/material";
import useFeatureFlags from "helper/useFeatureFlags";
import StaticDataTableComponent from "./StaticDataTableComponent";

const ListFilesComponent = ({ files, selectedFileIndex, setSelectedFilename, configureFileClicked, toggleVisibilityClicked, deleteFileClicked, isForeignPipe }) => {

    const { currentUserIsAdmin } = useFeatureFlags()

    const initialSelectionState = () => {
        let state = {}
        if (selectedFileIndex !== undefined && selectedFileIndex !== -1) {
            state[selectedFileIndex] = true
        }
        return state
    }

    const configureButtonClicked = (index) => {
        configureFileClicked(files[index]["File name"])
    }

    const toggleChangeVisibilityButtonClicked = (index) => {
        toggleVisibilityClicked(files[index]["File name"])
    }

    const deleteButtonClicked = (index) => {
        deleteFileClicked(files[index]["File name"])
    }

    const onRowSelected = (index) => {
        setSelectedFilename(files[index]["File name"])
    }

    const generateRowActionItems = ({ closeMenu, row }) => {
        return [
            <MenuItem key={0} disabled={isForeignPipe()} onClick={() => {
                closeMenu()
                configureButtonClicked(row.index)
            }}>
                Configure
            </MenuItem>,
            <MenuItem key={1} disabled={!currentUserIsAdmin()} onClick={() => {
                closeMenu()
                toggleChangeVisibilityButtonClicked(row.index)
            }}>
                Change visibility
            </MenuItem>,
            <MenuItem key={2} disabled={isForeignPipe()} onClick={() => {
                closeMenu()
                deleteButtonClicked(row.index)
            }}>
                Delete
            </MenuItem>
        ]
    }

    return (
        <StaticDataTableComponent
            data={files}
            initialSelectionState={initialSelectionState()}
            onRowSelection={onRowSelected}
            generateRowActionItems={generateRowActionItems}
            isForeignPipe={isForeignPipe}
        />
    )
}

export default ListFilesComponent;
