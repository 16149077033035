import { trimNumericalPrecisionTo4Digits } from 'helper/trimNumericalPrecisionTo4Digits';
import { MaterialReactTable } from 'material-react-table';
import { useEffect, useState } from 'react';

const DataTableComponent = ({
    data,
    reloadData = undefined,
    rowCount = undefined,
    isLoading = false
}) => {

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const columnsFromRows = (data) => {
        if (data.length > 0) {
            return Object.keys(data[0]).filter((column) => !column.includes("Unnamed"))
        } else {
            return []
        }
    }

    const columnDicts = (columns) => {
        if (data === undefined || data.length == 0) { return [] }
        let dicts = columns.map((column) => ({
            accessorKey: column,
            header: column
        }))
        return dicts
    }

    useEffect(() => {
        if (reloadData === undefined) {
            return
        }

        reloadData(
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sorting
        )
    }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
    ]);

    return (
        <MaterialReactTable
            // required props
            columns={columnDicts(columnsFromRows(data))}
            data={trimNumericalPrecisionTo4Digits(data)}

            // set feature flags
            enableStickyHeader
            enableTopToolbar={false}
            enableClickToCopy={false}
            enableColumnResizing
            enableFullScreenToggle={false}

            // remote pagination section
            manualFiltering
            manualPagination
            manualSorting
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            rowCount={rowCount}

            // row selection section
            selectAllMode={"all"}
            displayColumnDefOptions={{
                'mrt-row-select': { size: 20 },
                'mrt-row-actions': { size: 20 }
            }}

            // state management
            initialState={{
                density: 'compact',
                columnOrder: columnsFromRows(data),
                sorting: [{
                    id: columnsFromRows(data)[0],
                    desc: false
                }]
            }}
            muiTablePaginationProps={{
                labelRowsPerPage: ""
            }}
            state={{
                columnOrder: columnsFromRows(data),

                columnFilters: columnFilters,
                globalFilter: globalFilter,
                pagination: pagination,
                showProgressBars: isLoading,
                sorting: sorting,
            }}
        />
    )
}

export default DataTableComponent;