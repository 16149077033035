import { IconButton } from '@mui/material';
import {Cancel} from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { getMarkerEnd, useNodes } from "react-flow-renderer"
import {UserContext} from "../../../helper/UserContext";
import UserPermission from '../../../helper/UserPermission';
import { getSmartEdge, svgDrawStraightLinePath, pathfindingJumpPointNoDiagonal } from '@tisoap/react-flow-smart-edge';

const CustomFlowEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style,
    markerEndId,
    arrowHeadType,
    onCloseButtonClick
}) => {
    const foreignObjectSize = 40
    
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId)
    const nodes = useNodes()

    const edge = getSmartEdge({
		sourcePosition,
		targetPosition,
		sourceX,
		sourceY,
		targetX,
		targetY,
		nodes,
        options: {
            generatePath: pathfindingJumpPointNoDiagonal,
            drawEdge: svgDrawStraightLinePath
        }
	})

    const {user} = useContext(UserContext)
    const [shouldShowCloseButton, setShouldShowCloseButton] = useState(false)
    
    useEffect(() => {
        setShouldShowCloseButton(user?.permissions.some((p) => p.name === UserPermission.DELETE_EDGE))
    }, [user])
    
    return (<>
                <path
                    id={id}
                    style={style}
                    className="react-flow__edge-path"
                    d={edge?.svgPathString}
                    markerEnd={markerEnd}
                />
                {shouldShowCloseButton && 
                    (<foreignObject
                        width={foreignObjectSize}
                        height={foreignObjectSize}
                        x={edge?.edgeCenterX - foreignObjectSize / 2}
                        y={edge?.edgeCenterY - foreignObjectSize / 2}
                        className="edgebutton-foreignobject"
                        requiredExtensions="http://www.w3.org/1999/xhtml"
                    >
                        <div>
                            <IconButton color="inherit" onClick={(event) => {
                                event.stopPropagation()
                                onCloseButtonClick(id)
                            }}>
                                <Cancel/>
                            </IconButton>
                        </div>
                    </foreignObject>)}
        </>)
    }
    
    export default CustomFlowEdge
    