import BlockDetailsComponent from "components/PageComponents/BlockDetailsComponent";
import BlockPageComponent from "components/PageComponents/BlockPageComponent";
import { useIsForeignPipe } from "helper/UrlUtils";
import { useEffect, useRef, useState } from "react";

const BlockCellComponent = ({ block, pipeId, usernameFromPath, reload, onSelectionChanged }) => {

    const headerRef = useRef(null)
    const isForeignPipe = useIsForeignPipe(usernameFromPath);
    const [showInteractivePlot, setShowInteractivePlot] = useState(false)

    useEffect(() => {
        if (showInteractivePlot) {
            setShowInteractivePlot(false)
        }
    }, [reload])

    return (
        <div key={block.id} style={{ marginBottom: '20px' }}>
            <div
                ref={headerRef}
                style={{ borderBottom: '1px solid black', padding: '10px' }}
            >
                <BlockDetailsComponent
                    pipeId={pipeId}
                    block={block}
                    usernameFromPath={usernameFromPath}
                    isForeignPipe={isForeignPipe}
                    isOnDashboard={true}
                    showInteractivePlot={showInteractivePlot}
                    reload={reload}
                    onSelectionChanged={onSelectionChanged}
                    onShowInteractivePlotClicked={() => {
                        setShowInteractivePlot(!showInteractivePlot)
                    }}
                />
            </div>
            <div style={{ padding: '10px' }}>
                <BlockPageComponent
                    pipeId={pipeId}
                    nodeId={parseInt(block.flow_id)}
                    usernameFromPath={usernameFromPath}
                    reload={reload}
                    showTable={false}
                    isDashboardItem={true}
                    showStaticPlot={!showInteractivePlot}
                    onSelectionChanged={onSelectionChanged}
                />
            </div>
        </div>
    )
}

export default BlockCellComponent
