import { ThemeProvider } from "@mui/material";
import ErrorFallbackComponent from "ErrorFallbackComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import LoginPageComponent from "components/PageComponents/LoginPageComponent";
import PacmanPlotComponent from "components/PlotComponents/PacmanPlotComponent";
import LoadingSnackbar from "components/snackbars/LoadingSnackbar";
import { useEffect, useState } from 'react';
import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "react-helmet";
import { HashRouter as Router, Routes, Route as URLRoute } from "react-router-dom";
import "./App.css";
import DemoPageComponent from "./components/PageComponents/DemoPageComponent";
import ErrorSnackbar from "./components/snackbars/ErrorSnackbar";
import ToolbarPageComponent from "./components/toolbar/ToolbarPageComponent";
import Api from "./helper/Api";
import { ApiContext } from "./helper/ApiContext";
import Logger from "./helper/Logger";
import Route from "./helper/Route";
import { UserContext } from "./helper/UserContext";
import loadTheme from "./helper/theme";


const App = () => {
    const theme = loadTheme()
    const api = Api();
    const [user, setUser] = useState(undefined);

    useEffect(() => {
        Logger.init()

        if (!window.location.href.includes("/component/demo")) {
            api.getUserProfile().
                then((response) => {
                    setUser(response.data)
                }).catch((error) => {
                    if (error && error.status == 404 && window.location.pathname !== Route.Login) {
                        window.location.assign(Route.Home)
                    }
                    Logger.error("Error loading user profile: " + JSON.stringify(error))
                })
        }
    }, [])

    const logError = (error, info) => {
        Logger.error("ErrorBoundary: " + error + " " + info)
    }

    return (
        <div className="App">
            <Helmet>
                <style>{'body { background-color: transparent; }'}</style>
                <title>{theme.text.title.main}</title>
                <link rel="icon" href={theme.img.favicon.main} />
            </Helmet>
            <ErrorSnackbar error={api.currentError} />
            <ThemeProvider theme={theme}>
                <ErrorBoundary
                    FallbackComponent={ErrorFallbackComponent}
                    onError={logError}
                >
                    <ApiContext.Provider value={{ api }}>
                        <UserContext.Provider value={{ user, setUser }}>
                            <Router>
                                <Routes>
                                    <URLRoute path={Route.Demo} element={
                                        <DemoPageComponent />
                                    } />
                                    <URLRoute path={Route.Login} element={
                                        <LoginPageComponent />
                                    } />
                                    <URLRoute path={Route.AuthCallback} element={
                                        <LoadingSnackbar loading={true} />
                                    } />
                                    <URLRoute path={Route.Pacman} element={
                                        <PacmanPlotComponent />
                                    } />
                                    <URLRoute path="/*" element={
                                        <ToolbarPageComponent />
                                    } />
                                </Routes>
                            </Router>
                        </UserContext.Provider>
                    </ApiContext.Provider>
                </ErrorBoundary>
            </ThemeProvider>
        </div>
    );
};
export default App;
