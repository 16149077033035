import { Button, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import StaticDataTableComponent from 'components/table/StaticDataTableComponent';
import { useRef } from 'react';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1)
}

// massively inspired by https://mui.com/material-ui/react-transfer-list/
export const FilterListComponent = ({ title, items, filterIndex, isChecked, isForeignPipe, handleFilterChange, disabledRows }) => {

    const checkedItems = intersection(items, items.filter((row) => (isChecked(row))))
    const initialSelectionState = checkedItems.reduce((accumulator, checkedItem, index) => {
        if (checkedItem[title] !== undefined) {
            accumulator[checkedItem[title]] = true;
        }
        return accumulator;
    }, {});

    const selectedRows = useRef({})

    const hasUncheckedRows = checkedItems.length < items.length

    return (
        <Card>
            <StaticDataTableComponent
                data={items}
                categoricalFilter={true}
                category={title}
                isForeignPipe={isForeignPipe}
                handleFilterChange={(selectionState) => {
                    selectedRows.current = selectionState
                }}
                initialSelectionState={initialSelectionState}
                disabledRows={disabledRows[title]}
            />
            <Stack direction="row">
                <Button
                    variant="contained"
                    onClick={() => {
                        handleFilterChange(Object.keys(selectedRows.current))
                    }}
                    disabled={isForeignPipe()}
                    sx={{ margin: 2 }}
                >
                    Save
                </Button>
                <div style={{ flexGrow: 1 }} />
                {/* Show the index of the filter (+1 so it doesn't start at 0) to know the parent/child configuration */}
                {hasUncheckedRows && <Typography variant="body2" sx={{ alignContent: "center", mr: 3 }}>{parseInt(filterIndex) + 1}</Typography>}
            </Stack>
        </Card>
    )
}

export default FilterListComponent