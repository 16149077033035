import {FormControl, InputLabel, Select, Typography, Stack, Card} from "@mui/material";

const SelectedValueNode = ({values}) => {

    return (
        <Stack direction="column" spacing={2}>
            {values && values.map((value, index) => (
                <Card variant="outlined" key={index}>
                    <Typography 
                        variant="body1"
                        sx={{padding: 1}}
                    >
                        {value}
                    </Typography>
                </Card>
            ))}
        </Stack>
    )
}

export default SelectedValueNode