import { Box, CssBaseline, Stack, Typography } from "@mui/material";
import DefaultPaper from "components/DefaultPaper";
import { ToolbarBaseComponent } from "components/toolbar/ToolbarComponent";
import { ContactComponent } from "components/landingPage/LandingPageComponent";

const ErrorFallbackComponent = ({ error, resetErrorBoundary }) => {
    // TODO: Redirect to a safe location and call resetErrorBoundary() to reset the error boundary and retry the render.
  
    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <ToolbarBaseComponent>
                <Box sx={{flexGrow: 1}}/>
            </ToolbarBaseComponent>
            <Box sx={{
                padding: 4,
                maxWidth: "100vw"
            }}>
                <DefaultPaper>
                    <Stack direction="column" spacing={3}>
                        <Typography variant="h2">Error</Typography>
                        <Typography variant="body3">Something went wrong. The team was already informed, however if this error is causing you severe pain, please reach out to </Typography>
                        <ContactComponent/>
                        <Typography variant="h5" color="red">Error Message:</Typography>
                        <Typography variant="body2" color="red" style={{whiteSpace: "pre-line"}}>{error.stack}</Typography>
                    </Stack>
                </DefaultPaper>
            </Box>
        </Box>
    );
}

export default ErrorFallbackComponent;