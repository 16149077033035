import ClonePipeDialogComponent from "components/dialogs/ClonePipeDialogComponent";
import { encodePipeId, useIsForeignPipe, useURLParts } from "helper/UrlUtils";
import { UserContext } from "helper/UserContext";
import { toPng } from "html-to-image";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, { Background, Controls, useEdgesState, useNodesState } from "react-flow-renderer";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../helper/ApiContext";
import Logger from "../../helper/Logger";
import DefaultPaper from "../DefaultPaper";
import CustomFlowEdge from "./edges/CustomFlowEdge";
import CustomFlowNode from "./nodes/CustomFlowNode";

const PipelineGraphComponent = ({ height, width, onDrop, setReactFlowInstance, isReadOnly }) => {
    const { api } = useContext(ApiContext);
    const { user } = useContext(UserContext);
    const { pipeId, username, isTemplate } = useURLParts()
    const navigate = useNavigate();
    const [blocks, setBlocks] = useState([]);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    // TODO: find out why we have to do this workaround instead of telling react-flow to rerender directly
    const [rerenderFlowObservable, rerenderFlow] = useState(0);
    const isForeignPipe = useIsForeignPipe(username);
    const [dialogOpen, setDialogOpen] = useState(false)
    const pipeIdRef = useRef()
    pipeIdRef.current = pipeId

    useEffect(() => {
        if (!isNaN(pipeId)) {
            loadPipeFromApi()
        }
    }, [pipeId])

    const handleReadonlyError = () => {
        setDialogOpen(true)
    }

    const loadPipeFromApi = () => {
        if (isTemplate) {
            api.getTemplate(pipeId)
                .then((response) => {
                    let { blocks, connections } = response.data
                    setBlocks(blocks)
                    loadRemoteNodes(blocks)
                    loadRemoteEdges(connections)
                    handleReadonlyError()
                }).catch((error) => {
                    Logger.error("Could not get user pipe: " + JSON.stringify(error))
                })
        } else {
            api.getUserPipe(pipeId, username)
                .then((response) => {
                    let { blocks, connections } = response.data
                    setBlocks(blocks)
                    loadRemoteNodes(blocks)
                    loadRemoteEdges(connections)
                }).catch((error) => {
                    Logger.error("Could not get user pipe: " + JSON.stringify(error))
                })
        }
    }

    const loadRemoteNodes = (nodes) => {
        let patchedNodes = nodes.map((node) => {
            return {
                id: `${node.flow_id}`,
                data: node,
                position: node.position,
                type: "custom" // we need this to tell react-flow to use our custom node
            }
        })
        setNodes(patchedNodes)
    }

    const createEdgeDict = (base) => {
        return {
            id: `e${base.source}-${base.sourceHandle}-${base.target}-${base.targetHandle}`,
            type: 'custom',
            animated: 'true',
            style: { stroke: 'black' },
            ...base
        }
    }

    const loadRemoteEdges = (edges) => {
        let patchedEdges = edges.map((edge) => createEdgeDict({
            source: edge.source_flow_id.toString(),
            sourceHandle: edge.source_handle_id?.toString(),
            target: edge.target_flow_id.toString(),
            targetHandle: edge.target_handle_id?.toString()
        })
        )
        setEdges(patchedEdges)
    }

    const isValidConnection = (connection) => {
        // connection.source // string flow id
        // connection.sourceHandle = "TopRight" ...
        // connection.target // string flow id
        let otherEdgesOfTargetNode = edges.filter((edge) => (edge.target === connection.target && edge.targetHandle === connection.targetHandle))
        return otherEdgesOfTargetNode.length === 0 // can't connect two inputs to one handle
    }

    const onConnect = useCallback((params) => {
        if (isForeignPipe()) {
            handleReadonlyError()
            return
        }
        // first check if this connection is allowed
        if (edges.some((edge) => edge.target === params.target)) {
            // TODO: show an error?
            Logger.error("Cannot connect to a node that already has an incoming connection")
            return
        }

        // TODO: detect loops (eg. node A -> node B -> node A)

        let edgeDict = createEdgeDict(params)
        api.addEdgeToPipe(pipeIdRef.current, edgeDict, username).then((response) => {
            loadPipeFromApi()
            Logger.info("Successfully added edge to pipe")
        }).catch((error) => {
            Logger.error("Error updating user pipe: " + JSON.stringify(error))
        })
    }, [user])

    const onDragOver = useCallback((event) => {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
    }, [])

    const uploadScreenshot = () => {
        toPng(document.querySelector('.react-flow'), { pixelRatio: 0.5 })
            .then((data) => {
                api.uploadScreenshot(pipeIdRef.current, data, username)
                    .then((response) => {
                        Logger.info("Successfully uploaded screenshot")
                    }).catch((error) => {
                        Logger.error("Error uploading screenshot: " + JSON.stringify(error))
                    })
            });
    }

    const newUniqueNodeId = () => {
        let newNodeId = nodes.length + 1
        while (nodes.find((node) => node.id === newNodeId.toString())) {
            newNodeId += 1
        }
        return newNodeId.toString()
    }

    const addNode = (node) => {
        if (isForeignPipe() || !node) {
            handleReadonlyError()
            return
        }
        node.id = newUniqueNodeId()
        api.addNodeToPipe(pipeIdRef.current, node, username).then((response) => {
            loadPipeFromApi()
            Logger.info("Successfully added node to pipe")
        }).catch((error) => {
            Logger.error("Error updating user pipe: " + JSON.stringify(error))
        })
        // uploadScreenshot()
    }

    const onNodeClick = (event, node) => {
        if (document.getElementById("basic-menu") !== null) {
            // for some reason clicking away from the node context menu triggers the `onNodeClick` event
            // so we have to check if the menu is still there
            return
        }
        if (isTemplate) {
            // don't allow entering blocks in templates
            return
        }
        if (isReadOnly && !node.data.dashboard_configuration.view_in_dashboard) {
            // don't allow non-admins to go into blocks that are hidden from the dashboard
            return
        }
        // TODO: <finn> find a way to abort running requests on the backend that we will never see
        const pipeUrl = encodePipeId(username, pipeId)
        navigate(`/pipe/${pipeUrl}/block/${node.id}`)
    }

    const onNodeDragStop = (event, node) => {
        if (isForeignPipe()) {
            handleReadonlyError()
            return
        }
        if (node) {
            api.moveNodeInPipe(pipeIdRef.current, node.id, node.position, username).then((response) => {
                Logger.info("Successfully moved node in pipe")
            }).catch((error) => {
                Logger.error("Error updating user pipe: " + JSON.stringify(error))
            })
            // uploadScreenshot()
        }
    }

    const onDropdownValueChanged = (nodeId, selectedOptions) => {
        api.updateNodeInPipe(pipeIdRef.current, nodeId, username, selectedOptions).then((response) => {
            Logger.info("Successfully changed node selection in pipe")
            loadPipeFromApi()
        }).catch((error) => {
            Logger.error("Error updating user pipe: " + JSON.stringify(error))
        })
    }

    const onDeleteNode = (nodeId) => {
        if (isForeignPipe()) {
            handleReadonlyError()
            return
        }
        setNodes((nodes) => nodes.filter((node) => node.id !== nodeId))
        setEdges((edges) => edges.filter((edge) => edge.source !== nodeId && edge.target !== nodeId))
        api.deleteNodeFromPipe(pipeIdRef.current, nodeId, username).then((response) => {
            Logger.info("Successfully deleted node from pipe")
            loadPipeFromApi()
        }).catch((error) => {
            Logger.error("Error updating user pipe: " + JSON.stringify(error))
        })
        // uploadScreenshot()
    }

    const onChangeDashboardFlag = (nodeId, checked) => {
        api.updateNodeInDashboard(pipeIdRef.current, nodeId, username, checked).then((response) => {
            Logger.info("Successfully changed block dashboard flag")
        }).catch((error) => {
            Logger.error("Error updating the block: " + JSON.stringify(error))
        })
    }

    const onChangeSkipFlag = (nodeId, checked) => {
        api.updateNodeSkipFlag(pipeIdRef.current, nodeId, username, checked).then((response) => {
            Logger.info("Successfully changed block skip flag")
        }).catch((error) => {
            Logger.error("Error updating the block: " + JSON.stringify(error))
        })
    }

    const onDeleteEdge = (edgeId) => {
        if (isForeignPipe()) {
            handleReadonlyError()
            return
        }
        setEdges((edges) => edges.filter((edge) => edge.id !== edgeId))
        api.deleteEdgeFromPipe(pipeIdRef.current, edgeId, username).then((response) => {
            Logger.info("Successfully deleted edge from pipe")
            loadPipeFromApi()
        }).catch((error) => {
            Logger.error("Error updating user pipe: " + JSON.stringify(error))
        })
        // uploadScreenshot()
    }

    const nodeTypes = useMemo(() => ({
        custom: (reactFlowProps) => <CustomFlowNode
            pipeId={pipeId}
            usernameFromPath={username}
            isReadOnly={isReadOnly}
            isValidConnection={isValidConnection}
            onValueChanged={(selectedOption) => {
                onDropdownValueChanged(reactFlowProps.id, selectedOption)
            }}
            onDeleteButtonClick={(nodeId) => {
                onDeleteNode(nodeId)
            }}
            onCheckboxClick={(id, name, checked) => {
                if (name === 'Skip') {
                    onChangeSkipFlag(id, checked)
                } else {
                    onChangeDashboardFlag(id, checked)
                }
            }}
            {...reactFlowProps}
        />,
    }), [rerenderFlowObservable, user])

    const edgeTypes = useMemo(() => ({
        custom: (reactFlowProps) => <CustomFlowEdge
            onCloseButtonClick={(edgeId) => {
                onDeleteEdge(edgeId)
            }}
            {...reactFlowProps}
        />
    }), [rerenderFlowObservable, user])

    return (
        <DefaultPaper additionalSx={{
            height: height,
            width: width,
            padding: -10, // react flow has some padding by default which we don't want
            overflow: "hidden",
        }}>
            <ClonePipeDialogComponent
                open={dialogOpen}
                setOpen={setDialogOpen}
                usernameFromPath={username}
                pipeId={pipeId}
                isTemplate={isTemplate}
            />
            <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodesDraggable={!isReadOnly}
                nodesConnectable={!isReadOnly}
                elementsSelectable={!isReadOnly}
                onInit={setReactFlowInstance}
                onDrop={(event) => {
                    addNode(onDrop(event))
                }}
                onDragOver={onDragOver}
                onNodeClick={onNodeClick}
                onNodeDragStop={onNodeDragStop}
                fitView
            >
                <Background
                    color="#000000"
                    style={{ backgroundColor: "#F5f5f5" }}
                />
                <Controls />
            </ReactFlow>
        </DefaultPaper>
    )
}

export default PipelineGraphComponent