import {Button, Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Box from "@mui/material/Box";
import {Close} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import * as React from "react";

const LicensePopupComponent = ({open, onClose}) => {

    const licenseTexts = require("../../data/licenses.json")

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            sx={{mt: 8}}
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                <Box style={{display: "flex"}}>
                    Licenses of Open Source Components used by NoMaze
                    <Button sx={{ml: "auto"}} onClick={onClose}><Close/></Button>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {Object.entries(licenseTexts).map(([key, value], index) => (
                        <Box key={index}>
                            <Typography variant="h5">{`${key}: ${value.licenses}`}</Typography>
                            <Typography variant="h6">{value.copyright}</Typography>
                            <Typography variant="body1">{value.licenseText}</Typography>
                            <br></br>
                        </Box>
                    ))}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default LicensePopupComponent