import {Button, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {ApiContext} from "../../helper/ApiContext";
import {useState, useContext} from "react";
import * as React from "react";

const RenamePopupComponent = ({pipeId, open, onClose, usernameFromPath}) => {
    const {api} = useContext(ApiContext);
    const [name, setName] = useState("")

    const updatePipe = (name) => {
        if (!isNaN(pipeId) && name !== "") {
            api.renamePipe(pipeId, name, usernameFromPath)
                .then((response) => {
                    console.log("Successfully renamed pipe")
                }).catch((error) => {
                    console.log("Error renaming pipe: " + JSON.stringify(error))
                })
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
        <DialogTitle>Rename Pipeline</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Please enter a new name and description for the pipeline.
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                label="Name"
                fullWidth
                variant="outlined"
                onChange={(event) => {
                    setName(event.target.value)
                }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={() => {
                updatePipe(name)
                onClose()
            }} variant="contained">Save</Button>
        </DialogActions>
    </Dialog>
    )
}

export default RenamePopupComponent