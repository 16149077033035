import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "./UserContext";

export const useIsForeignPipe = (usernameFromPath) => {
    const { user } = useContext(UserContext)
    return () => {
        // TODO: allow super admins to edit all pipes
        if (user === undefined || usernameFromPath === undefined) {
            return false
        }
        return user.username !== usernameFromPath
    }
}

export const useURLParts = () => {
    var result = {
        pipeId: undefined,
        username: undefined,
        isDashboard: undefined,
        nodeId: undefined,
        isTemplate: false
    }
    const location = useLocation() // maybe window.location.pathname is enough?
    let parts = location.pathname.split("/")
    if (parts.length >= 2 && parts[1] === "pipes") {
        result.username = parts[2]
    } else if (parts.length >= 2 && parts[1] === "template") {
        result.pipeId = parseInt(parts[2])
        result.isTemplate = true
    } else if (parts.length >= 3) {
        const decodedPathParts = decodePipeId(parts[2])
        const pipeId = parseInt(decodedPathParts[1])
        const usernameFromPath = decodedPathParts[0]
        result.pipeId = pipeId
        result.username = usernameFromPath
        // some parts of the path are optional
        if (parts.includes("dashboard")) {
            result.isDashboard = true
        } else if (parts.includes("pipe")) {
            result.isDashboard = false
        }
    }
    if (parts.length > 4 && parts[3] === "block") {
        result.nodeId = parseInt(parts[4])
    }
    return result
}

// this function encodes the text to a hexidecimal output (2 characters per letter)
export const encodePipeId = (username, pipeId) => {
    const enc = new TextEncoder();
    const encStr = enc.encode(`${username}|${pipeId}`);
    const arrStr = Array.from(encStr, point => point.toString(16));
    const returnString = arrStr.join('');
    return returnString;
}

export const decodePipeId = (encodedData) => {
    if (encodedData === undefined) {
        return undefined
    }
    const dec = new TextDecoder();
    // this uses a regex to split into 2-character groups
    const hexArr = encodedData.match(/../g);
    const buffer = Uint8Array.from(hexArr, point => parseInt(point, 16));
    const text = dec.decode(buffer);
    return text.split('|');
}