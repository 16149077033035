import DataTransferKey from "../../../helper/DataTransferKey";
import DefaultPaper from "../../DefaultPaper";
import {BlockIcon} from "./BlockIcon";
import Typography from "@mui/material/Typography";
import { Card, Tooltip } from "@mui/material";

const BlockLibrarySidebarItem = ({block_blueprint}) => {

    const onDragStart = (event) => {
        event.dataTransfer.setData(DataTransferKey.NODE_DATA, JSON.stringify(block_blueprint))
        event.dataTransfer.effectAllowed = 'move'
    }

    return (
        <div
            onDragStart={onDragStart}
            draggable
        >
            <Tooltip title={block_blueprint.description}>
                <Card sx={{
                    padding: 2, 
                    display: 'flex', 
                    flexDirection: "row"
                }}>
                    <BlockIcon type={block_blueprint.type}/>
                    <Typography variant="body1" sx={{ml: 1}}>{block_blueprint.title}</Typography>
                </Card>
            </ Tooltip>
        </div>
    )
}

export default BlockLibrarySidebarItem