export const ProcessBlocks = ['filter_1d', 'filter_2d', 'filter_multi', 'head_to_head', 'regression', 'h2h_comparison', 'pca', 'box_plot'];
export const SkippableBlocks = ['breakout', 'filter_orthogonal'];
export const CheckboxNames = ['Show in dashboard', 'Skip'];
export const TableBlocks = ["pivot", "merge", "export", "breakout", "filter_orthogonal", "mean", "gca"];

export const clonePipeMessage = (Icon) => {
    return (
        <>
            You are in readOnly mode. Click on <b> 'Clone Pipe <Icon />'</b> on the toolbar to be able to edit the pipe.
        </>
    )
}