import {Button, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {ApiContext} from "../../helper/ApiContext";
import {useState, useContext} from "react";
import * as React from "react";
import Logger from "helper/Logger";

const SaveTemplatePopupComponent = ({pipeId, open, usernameFromPath, onClose}) => {
    const {api} = useContext(ApiContext);

    const savePipeAsTemplate = () => {
        if (!isNaN(pipeId)) {
            api.createTemplate(pipeId, usernameFromPath)
                .then((response) => {
                    Logger.info("Successfully saved pipe as template")
                })
                .catch((error) => {
                    Logger.error("Could not save pipe as template: " + JSON.stringify(error))
                })
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
        <DialogTitle>Save Pipe as Template</DialogTitle>
        <DialogContent>
            <DialogContentText>
                This pipeline will be saved as a template and can be used by all users to create new pipelines.
                Please note that all input files set in this pipeline will be shared with all users as well.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={() => {
                savePipeAsTemplate()
                onClose()
            }} variant="contained">Save</Button>
        </DialogActions>
    </Dialog>
    )
}

export default SaveTemplatePopupComponent