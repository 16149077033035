import {Vega} from "react-vega";

const PacmanPlotComponent = () => {

    const pacmanSpec = require("../../specs/pacman.vg.json");

    return (
        <Vega spec={pacmanSpec}/>
    )
}

export default PacmanPlotComponent;
