import { Box, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { ApiContext } from "../../helper/ApiContext";
import Logger from "../../helper/Logger";
import ErrorSnackbar from "../snackbars/ErrorSnackbar";
import DataTableComponent from "./DataTableComponent";

const DataLoadingTableComponent = React.memo(({ pipeId, nodeId, username, reloadPipeCounter, reloadPipe = undefined, preview = false }) => {
    const { api } = useContext(ApiContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [reloadTableCounter, reloadTable] = useState(0)

    useEffect(() => {
        reloadData()
        // TODO: cache the filter values and only reload if they change
    }, [reloadPipeCounter, reloadTableCounter])

    const dataShape = useMemo(() => {
        let breakouts = data.length
        if (breakouts == 0) { return [0, 0, 0] }
        let firstBreakout = data[0]
        // if we have metadata we use that, otherwise we fall back to the length of the df
        // we do this, because with paging enabled the df might not contain all rows
        let rows = (firstBreakout.meta) ? firstBreakout.meta.length : firstBreakout.df.length
        if (firstBreakout.df.length === 0) { return [breakouts, 0, 0] }
        let columns = Object.keys(firstBreakout.df[0]).length
        return [breakouts, rows, columns]
    },[data])

    const reloadData = useCallback((
        columnFilters,
        globalFilter,
        pageIndex,
        pageSize,
        sorting
    ) => {
        if (pipeId === undefined || nodeId === undefined || username === undefined || loading) {
            return
        }
        setLoading(true)
        // TODO: merge global filters and brush filters
        api.runPipe(
            pipeId,
            nodeId,
            preview ? 10 : undefined,
            username,

            `${(pageIndex ?? 0) * (pageSize ?? 10)}`,
            `${(pageSize ?? 10)}`,
            JSON.stringify(columnFilters ?? []),
            globalFilter ?? '',
            JSON.stringify(sorting ?? [])
        ).then((response) => {
            let breakouts = response.data.map((dict) => ({
                ...dict,
                df: JSON.parse(dict.df),
            }))
            setData(breakouts)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            Logger.error("Could not get contents for file: " + JSON.stringify(error))
            if (error && error.status == 404) {
                setError("Selected file could not be found on the server. Please select another file or reupload.")
                setTimeout(() => {
                    setError(null)
                }, 5000)
            }
        })
    },[api, pipeId, nodeId, username, loading, preview])

    return (
        <Stack direction="column" sx={{ overflow: "scroll" }}>
            <ErrorSnackbar error={error} />
            <Stack direction="row" spacing={2}>
                {data.map((breakout, index) => (
                    <Box key={index} sx={{ width: "100%" }}>
                        <Typography variant="h6" align="center">{breakout.meta.breakout}</Typography>
                        <DataTableComponent
                            data={breakout.df}
                            reloadData={reloadData}
                            rowCount={breakout.meta.length}
                            isLoading={loading}
                            checkboxSelection={true}
                        />
                    </Box>))}
            </Stack>
            <Typography variant="body">{dataShape.join(" x ")}</Typography>
        </Stack>
    )
})

export default DataLoadingTableComponent