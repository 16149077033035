import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { ApiContext } from 'helper/ApiContext';
import { clonePipeMessage } from 'helper/Constants';
import Logger from 'helper/Logger';
import Route from 'helper/Route';
import { encodePipeId } from 'helper/UrlUtils';
import { UserContext } from 'helper/UserContext';
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";

const ClonePipeDialogComponent = ({ open, setOpen, usernameFromPath, pipeId, isTemplate }) => {

    const { api } = useContext(ApiContext);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    const onCloneClick = () => {
        if (isTemplate) {
            api.createNewPipe("template clone", pipeId)
                .then((response) => {
                    setOpen(false);
                    const clonedPipeId = encodePipeId(user.username, response.data.pipe_id);
                    navigate(`${Route.Dashboard}/${clonedPipeId}`);
                }).catch((error) => {
                    Logger.error("Could not clone pipe: " + JSON.stringify(error))
                })
        } else {
            api.clonePipe(pipeId, usernameFromPath)
                .then((response) => {
                    setOpen(false);
                    const clonedPipeId = encodePipeId(user.username, response.data.pipe_id);
                    navigate(`${Route.Dashboard}/${clonedPipeId}`);
                }).catch((error) => {
                    Logger.error("Could not clone pipe: " + JSON.stringify(error))
                })
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                onClick={(event) => event.stopPropagation()}
            >
                <DialogTitle>Do you want to edit the pipe?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {clonePipeMessage(ContentCopyIcon)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Stack
                        direction="row"
                        justifyContent="space-around"
                        spacing={20}
                        minWidth={"100%"}
                    >
                        <Button variant="outlined" size="small" endIcon={<ContentCopyIcon />} onClick={onCloneClick}>Clone Pipe</Button>
                        <Button variant="outlined" size="small" endIcon={<AutoStoriesIcon />} onClick={handleClose}>Continue Readonly Mode</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ClonePipeDialogComponent;