// borrowed from https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da
const LocalStorageService = () => {

    const setToken = (token) => {
        localStorage.setItem("access_token", token)
    }

    const getAccessToken = () => {
        return localStorage.getItem("access_token")
    }

    const clearToken = () => {
        localStorage.removeItem("access_token")
    }

    return {
        setToken: setToken,
        getAccessToken: getAccessToken,
        clearToken: clearToken
    }
};

export default LocalStorageService;
