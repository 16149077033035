import React, {useContext, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContentText, DialogTitle, Stack, TextField, Typography} from "@mui/material";
import Route from "../../helper/Route";
import Logger from "../../helper/Logger";
import {ApiContext} from "../../helper/ApiContext";
import DefaultPaper from "../DefaultPaper";

export const SignupComponent = ({onSignupButtonClick}) => {
    
    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");

    return (
        <Stack direction="column" spacing={2}>
            <Typography variant="h4">Create Account</Typography>
            <TextField 
                label="username" 
                value={username} 
                variant="outlined"
                onChange={(e) => {
                    setUsername(e.target.value)
                }}
            />
            <TextField 
                label="name" 
                value={name} 
                variant="outlined"
                onChange={(e) => {
                    setName(e.target.value)
                }}
            />
            <TextField 
                label="password" 
                type="password" 
                variant="outlined"
                value={password} 
                onChange={(e) => {
                    setPassword(e.target.value)
                }}
            />
            <Button 
                variant="contained" 
                onClick={() => {
                    onSignupButtonClick(username, name, password)
                }}
            >
                Sign up
            </Button>
        </Stack>
    )
}

const SignupPageComponent = ({onClose}) => {
    const {api} = useContext(ApiContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorOccured, setErrorOccured] = useState(false)
    const [signupSuccess, setSignupSuccess] = useState(false);

    const signup = (username, name, password) => {
        if (username === "" || name === "" || password === "") {
            setErrorMessage("You have to fill in the values!")
            setErrorOccured(true)
            return
        }
        api.signup({
            username: username,
            name: name,
            password: password
        })
            .then((response) => {
                Logger.info("Sign up success: " + response)
                setErrorMessage("")
                setErrorOccured(false)
                setSignupSuccess(true)
            })
            .catch((err) => {
                Logger.error("Sign up error: " + err)
                setErrorOccured(true)
                if (err.response.data && err.response.data.type)
                    setErrorMessage(err.response.data.type)
                else
                    setErrorMessage(err.message)
            })
    }

    return (
        <DefaultPaper additionalSx={{width: 250, mt: 0, ml: "auto", mr: "auto"}}>
            <Stack direction="column" spacing={2}>
                <SignupComponent
                    onSignupButtonClick={signup}
                />
                {errorOccured && (
                    <div className="alert alert-light" role="alert">
                        {errorMessage}
                    </div>
                )}
                <Dialog open={signupSuccess} onClose={() => {
                    setSignupSuccess(false)
                }}>
                    <DialogTitle>Signup Successful</DialogTitle>
                    <DialogContentText>A new account has been created</DialogContentText>
                    <DialogActions>
                        <Button variant="contained" onClick={() => { 
                            setSignupSuccess(false)
                            onClose()
                        }}>Okay</Button>
                    </DialogActions>
                </Dialog>
            </Stack>
        </DefaultPaper>
    )
}

export default SignupPageComponent;