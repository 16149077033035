import React, {useContext, useEffect, useState} from "react";
import {Button, Stack, TextField} from "@mui/material";
import Route from "../../helper/Route";
import {NavLink, useNavigate} from "react-router-dom";
import Logger from "../../helper/Logger";
import {ApiContext} from "../../helper/ApiContext";
import DefaultPaper from "../DefaultPaper";


const LoginPageComponent = ({isWidget}) => {
    const {api} = useContext(ApiContext);
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorOccured, setErrorOccured] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const styling = isWidget===true? { flexDirection: "column", padding: "20px", margin: "20px", height: "100%" }:{padding: 5, width: 320, ml: "auto", mr: "auto"}

    const onLoginButtonClick = () => {
        if (username === "" || password === "") {
            setErrorMessage("You have to fill in the values!")
            setErrorOccured(true)
            return
        }
        api.login({
            user: username,
            password: password
        }).then((response) => {
            Logger.info("Login success: " + response)
            setErrorMessage("")
            setErrorOccured(false)
            
            navigate(Route.Home)
            window.location.reload()
        }).catch((err) => {
            Logger.error("Login error: " + err)
            setErrorOccured(true)
            if (err && err.response && err.response.data && err.response.data.type) {
                setErrorMessage(err.response.data.type)
            } else {
                setErrorMessage(err.message)
            }
        })
    }

    return (
        <DefaultPaper additionalSx={styling}>
            <Stack direction="column" spacing={2}>
                <h2>Login</h2>
                <TextField 
                    label="username" 
                    value={username} 
                    onChange={(e) => { setUsername(e.target.value) }}
                />
                <TextField 
                    label="password" 
                    type="password" 
                    value={password} 
                    onChange={(e) => { setPassword(e.target.value) }}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            onLoginButtonClick()
                        }
                    }}
                />
                <Button variant="contained" onClick={onLoginButtonClick}>Login</Button>
                {errorOccured && (
                    <div className="alert alert-light" role="alert">
                        {errorMessage}
                    </div>
                )}
            </Stack>
        </DefaultPaper>
    )
}

export default LoginPageComponent;