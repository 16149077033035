import * as React from 'react';
import {useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Copyright, Settings, LibraryAdd, Person} from "@mui/icons-material";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ListSubheader from "@mui/material/ListSubheader";
import LicensePopupComponent from "./LicensePopupComponent";
import RenamePopupComponent from "./RenamePopupComponent";
import {ApiContext} from "../../helper/ApiContext";
import Logger from 'helper/Logger';
import {useNavigate} from "react-router-dom";
import SuccessSnackbar from 'components/snackbars/SuccessSnackbar';
import Route from 'helper/Route';
import { UserContext } from 'helper/UserContext';
import UserPermission from 'helper/UserPermission';
import SaveTemplatePopupComponent from './SaveTemplatePopupComponent';
import { useIsForeignPipe } from 'helper/UrlUtils';

const SettingsDrawerComponent = ({open, onClose, pipeId, usernameFromPath}) => {

    const {api} = useContext(ApiContext)
    const {user} = useContext(UserContext)
    const navigate = useNavigate();
    const [licensePopupVisible, setLicensePopupVisible] = useState(false)
    const [saveTemplatePopupVisible, setSaveTemplatePopupVisible] = useState(false)
    const [renamePopupVisible, setRenamePopupVisible] = useState(false)
    const isForeignPipe = useIsForeignPipe(usernameFromPath);
    let [message, setMessage] = useState(null);

    // currently only admins can see the user management and save as template buttons
    // we might want to introduce more granular permissions in the future
    const shouldShowUserManagementButton = () => (
        user !== undefined && user.permissions.some((p) => p.name === UserPermission.MANAGE_USERS)
    )

    const shouldShowSaveAsTemplateButton = () => (
        user !== undefined && user.permissions.some((p) => p.name === UserPermission.ADMIN)
    )

    return (
        <Drawer
            anchor={"right"}
            open={open}
            onClose={onClose}
        >
            <Box
                sx={{mt: 8, width: 250}}
                role="presentation"
            >
                <List
                    subheader={
                        <ListSubheader component="div">
                            General Settings
                        </ListSubheader>
                    }
                >
                    {shouldShowUserManagementButton() && <ListItem key={1} disablePadding>
                        <ListItemButton onClick={() => {
                            navigate(Route.UserManagement)
                            onClose()
                        }}>
                            <ListItemIcon>
                                <Person/>
                            </ListItemIcon>
                            <ListItemText primary={"User Management"}/>
                        </ListItemButton>
                    </ListItem>}

                    <ListItem key={1} disablePadding>
                        <ListItemButton onClick={() => setLicensePopupVisible(true)}>
                            <ListItemIcon>
                                <Copyright/>
                            </ListItemIcon>
                            <ListItemText primary={"Show OSS Licenses"}/>
                        </ListItemButton>
                    </ListItem>

                    {!isNaN(pipeId) &&
                        <ListItem key={2} disablePadding>
                            <ListItemButton disabled={isForeignPipe()} onClick={() => setRenamePopupVisible(true)}>
                                <ListItemIcon>
                                    <DriveFileRenameOutlineIcon/>
                                </ListItemIcon>
                                <ListItemText primary={"Rename Pipe"}/>
                            </ListItemButton>
                        </ListItem>}
                    
                    {shouldShowSaveAsTemplateButton() && !isNaN(pipeId) &&
                        <ListItem key={3} disablePadding>
                            <ListItemButton disabled={isForeignPipe()} onClick={() => setSaveTemplatePopupVisible(true)}>
                                <ListItemIcon>
                                    <LibraryAdd/>
                                </ListItemIcon>
                                <ListItemText primary={"Save Pipe as Template"}/>
                            </ListItemButton>
                        </ListItem>}
                </List>
            </Box>
            <LicensePopupComponent open={licensePopupVisible} onClose={() => setLicensePopupVisible(false)}/>
            <SaveTemplatePopupComponent pipeId={pipeId} open={saveTemplatePopupVisible} usernameFromPath={usernameFromPath} onClose={() => setSaveTemplatePopupVisible(false)}/>
            <RenamePopupComponent pipeId={pipeId} open={renamePopupVisible} usernameFromPath={usernameFromPath} onClose={() => setRenamePopupVisible(false)}/>

            <SuccessSnackbar message={message}/>
        </Drawer>
    )
}

export default SettingsDrawerComponent