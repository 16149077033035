import { useEffect, useState } from "react";

const { Stack, Typography, TextField, Button } = require("@mui/material");

const CreateSubgroupNameComponent = ({disabled, initialTitle, onSaveButtonClick}) => {
    const [title, setTitle] = useState("")

    useEffect(() => {
        setTitle(initialTitle)
    }, [initialTitle])

    return (
        <Stack direction="row" spacing={2} sx={{alignItems: 'center'}}>
            <Typography variant="body3" >Name of the subgroup:</Typography>
            <TextField required margin="dense" id="name" label="Subgroup Name" value={title} onChange={(event) => {
                setTitle(event.target.value);
            }}/>
            <Button
                variant="contained"
                onClick={() => {
                    onSaveButtonClick(title)
                }}
                disabled={disabled}
                sx={{ml: 2, mt: 1, mb: 0}}
            >
                Save
            </Button>
        </Stack>
    )
}

export default CreateSubgroupNameComponent