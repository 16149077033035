import {Handle, Position} from "react-flow-renderer";

export const LeftHandle = ({isValidConnection}) => {
    return (
        <Handle
            id="Left"
            type="target"
            position={Position.Left}
            style={{width: "20px", height: "20px", left: "-10px"}}
            isValidConnection={isValidConnection}
        />
    )
}

export const TopLeftHandle = ({isValidConnection}) => {
    return (
        <Handle
            id="TopLeft"
            type="target"
            position={Position.Left}
            style={{width: "20px", height: "20px", left: "-10px", top: "50%", marginTop: "-25px"}}
            isValidConnection={isValidConnection}
        />
    )
}

export const BottomLeftHandle = ({isValidConnection}) => {
    return (
        <Handle
            id="BottomLeft"
            type="target"
            position={Position.Left}
            style={{width: "20px", height: "20px", left: "-10px", top: "50%", marginTop: "25px"}}
            isValidConnection={isValidConnection}
        />
    )
}

export const RightHandle = ({isValidConnection}) => {
    return (
        <Handle
            id="Right"
            type="source"
            position={Position.Right}
            style={{width: "20px", height: "20px", right: "-10px"}}
            isValidConnection={isValidConnection}
        />
    )
}

export const TopRightHandle = ({isValidConnection}) => {
    return (
        <Handle
            id="TopRight"
            type="source"
            position={Position.Right}
            style={{width: "20px", height: "20px", right: "-10px", top: "50%", marginTop: "-25px"}}
            isValidConnection={isValidConnection}
        />
    )
}

export const BottomRightHandle = ({isValidConnection}) => {
    return (
        <Handle
            id="BottomRight"
            type="source"
            position={Position.Right}
            style={{width: "20px", height: "20px", right: "-10px", top: "50%", marginTop: "25px"}}
            isValidConnection={isValidConnection}
        />
    )
}