import {Box, Snackbar, SnackbarContent} from "@mui/material";
import {Error} from "@mui/icons-material";
import Typography from "@mui/material/Typography";

const SuccessSnackbar = ({message}) => {
    return (
        <Snackbar
            sx={{mt: 10}}
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            open={message !== null}
        >
            <SnackbarContent
                style={{backgroundColor: "#5ed569"}}
                message={
                    message !== null &&
                    <Box sx={{display: 'inline-flex', flexDirection: 'row'}}>
                        <Error/>
                        <Box sx={{ml: 2, display: 'flex', alignItems: "center", justifyContent: "center"}}>
                            <Typography>{message}</Typography>
                        </Box>
                    </Box>
                }
            />
        </Snackbar>
    )
}

export default SuccessSnackbar;