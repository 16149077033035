import { Box, Dialog, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../../helper/ApiContext";
import Logger from "../../../helper/Logger";
import DefaultPaper from "../../DefaultPaper";
import BlockLibraryPageComponent from "../../PageComponents/BlockLibraryPageComponent";
import BlockLibrarySidebarItem from "./BlockLibrarySidebarItem";

const BlockLibrarySidebarComponent = ({ height }) => {

    const { api } = useContext(ApiContext);
    const [blockBlueprints, setBlockBlueprints] = useState([])
    const [popupOpen, setPopupOpen] = useState(false)

    useEffect(() => {
        api.getBlocks()
            .then((response) => setBlockBlueprints(response.data))
            .catch((error) => Logger.error(error))
    }, [])

    return (
        <Box sx={{ mt: 0 }}>
            <DefaultPaper>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        display: "flex",
                        height: height - 150,
                        overflow: "hidden",
                        overflowY: "scroll",
                    }}
                >
                    <Typography variant="h5">Block Library</Typography>
                    <Typography variant="body1">You can drag these nodes onto the canvas.</Typography>
                    {/* TODO: find a way to tie the block library height to the viewport of the Flow */}
                    {blockBlueprints.slice(0, 28).map((block_blueprint, index) => (
                        <BlockLibrarySidebarItem block_blueprint={block_blueprint} key={index} />
                    ))}
                </Stack>
                <Dialog open={popupOpen} fullWidth={true} onClose={() => {
                    setPopupOpen(false)
                }}>
                    <BlockLibraryPageComponent blocks={blockBlueprints} />
                </Dialog>
            </DefaultPaper>
        </Box>
    );
};

export default BlockLibrarySidebarComponent