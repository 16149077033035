import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import DefaultPaper from "../DefaultPaper";
import { BlockIcon } from "../flow/sidebar/BlockIcon";

// TODO: move to own file
const BlockLibraryItem = ({ block }) => {

    const Guts = ({ data }) => {
        switch (data.type) {
            case "single_select":
                return <></>
            default:
                return <></>
        }
    }

    return (
        <DefaultPaper additionalSx={{ mt: 0 }}>
            <BlockIcon type={block.type} />
            <Typography variant="body1" sx={{ ml: 1 }}>{block.title}</Typography>
            {block.children.map((child) => (
                <Guts data={child} />
            ))}
        </DefaultPaper>
    )
}

const BlockLibraryPageComponent = ({ blocks }) => {
    return (
        <DefaultPaper additionalSx={{ mt: 0 }}>
            <Stack direction="column" spacing={2}>
                <Typography variant={"h3"}>Block Library</Typography>
                <Typography variant={"body1"}>Here you can find predefined import, processing and export blocks built by
                    the NoMaze team. We are continuously adding new blocks - if you want to see a specific feature soon,
                    please let us know!</Typography>
                {blocks.map((block, index) => (
                    <BlockLibraryItem block={block.data} key={index} />
                ))}
            </Stack>
        </DefaultPaper>
    )
}

export default BlockLibraryPageComponent