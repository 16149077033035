import {CheckBox, Merge} from "@mui/icons-material";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";

const IconNode = ({config}) => {
    const possibleIcons = {
        "CheckBox": CheckBox,
        "Merge": Merge
    }

    const Icon = possibleIcons[config.options.icon] // TODO: support multiple children

    return (
        <Stack direction="row">
            <Icon/>
            <Typography sx={{ml: 1}} variant="body1">Click for more options</Typography>
        </Stack>
    )
}

export default IconNode