import {Box, CircularProgress, Snackbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";

const LoadingSnackbar = ({loading}) => {
    return (
        <Snackbar
            sx={{mt: 7.5}}
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            open={loading}
            message={
                <Box sx={{display: 'inline-flex', flexDirection: 'row'}}>
                    <CircularProgress size={30} color={"inherit"}/>
                    <Box sx={{ml: 2, display: 'flex', alignItems: "center", justifyContent: "center"}}>
                        <Typography>Loading...</Typography>
                    </Box>
                </Box>
            }
        />
    )
}

export default LoadingSnackbar;