import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { PictureAsPdf } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { TableBlocks } from "helper/Constants";
import Logger from "helper/Logger";
import { UserContext } from "helper/UserContext";
import UserPermission from "helper/UserPermission";
import useFeatureFlags from "helper/useFeatureFlags";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { ApiContext } from "../../helper/ApiContext";
import LazyLoad from '../LazyLoadComponent';
import BlockCellComponent from "./BlockCellComponent";


const DashboardComponent = React.memo(({ pipeId, usernameFromPath }) => {

    const { api } = useContext(ApiContext);
    const [pipe, setPipe] = useState({});
    const [reloadCounter, reload] = useState(0)
    const { user } = useContext(UserContext)
    const pdfDivRef = useRef(null);
    const isAdmin = user?.permissions.some((p) => p.name === UserPermission.ADMIN)
    const { enableLazyLoading, enableDragToReorder } = useFeatureFlags()
    const [blocksOrder, setBlocksOrder] = useState([])


    const onSelectionChanged = () => {
        reload(reloadCounter + 1)
    }

    useEffect(() => {
        api.getUserPipe(pipeId, usernameFromPath)
            .then((response) => {
                const pipe = response.data
                setPipe(pipe)
                setBlocksOrder(response.data.blocks.sort((a, b) => a.order - b.order).map((block) => block.flow_id))
            }).catch((error) => {
                Logger.error("Could not get user pipe: " + JSON.stringify(error))
            })
    }, [pipeId, reloadCounter])

    const blocks = useMemo(() => {
        if (pipe.blocks === undefined)
            return []
        let fileImportBlock = pipe.blocks?.find((block) => block.blueprint.type === "import")
        if (fileImportBlock === undefined) {
            Logger.error("Could not find file import block")
            return []
        }
        let sortedBlocks = []
        if (blocksOrder.length === 0 || !enableDragToReorder) {
            // fall back to default order if no order is set
            let visitedBlocks = []
            let queue = [fileImportBlock]
            while (queue.length > 0) {
                let block = queue.shift()
                if (visitedBlocks.includes(block.flow_id))
                    continue
                visitedBlocks.push(block.flow_id)
                sortedBlocks.push(block)
                let connections = pipe.connections.filter((connection) => connection.source_flow_id === block.flow_id)
                connections.forEach((connection) => {
                    let targetBlock = pipe.blocks.find((block) => block.flow_id === connection.target_flow_id)
                    if (targetBlock !== undefined)
                        queue.push(targetBlock)
                })
            }
        } else {
            sortedBlocks = blocksOrder.map((blockId) => pipe.blocks.find((block) => block.flow_id === blockId))
        }
        let filteredBlocks = sortedBlocks.filter((block) => block.dashboard_configuration.view_in_dashboard)
        return filteredBlocks
    }, [pipe.blocks, blocksOrder])

    const onDragEnd = (result) => {
        if (!result.destination || !result.source) return;
        const reorderedBlocks = Array.from(blocksOrder)
        const sourceBlockId = parseInt(result.draggableId)
        const sourceIndex = reorderedBlocks.indexOf(sourceBlockId);
        const [removed] = reorderedBlocks.splice(sourceIndex, 1);
        reorderedBlocks.splice(result.destination.index, 0, removed)
        setBlocksOrder(reorderedBlocks)
        const updatedOrder = reorderedBlocks.map((blockId, index) => ({ id: blockId, order: index }))
        api.updateBlockOrder(pipeId, updatedOrder, usernameFromPath)
            .then(response => {
                console.log("Block order updated successfully")
            })
            .catch(error => {
                console.error("Failed to update block order", error)
            })
    };

    const renderPDF = () => {
        setTimeout(() => {
            const doc = new jsPDF({
                format: 'a4',
                unit: 'px',
            });
            html2canvas(pdfDivRef.current, { scale: 1.5 }).then((data) => {
                const img = data.toDataURL("image/png")
                const imgProperties = doc.getImageProperties(img)
                var docWidth = doc.internal.pageSize.getWidth()
                var docHeight = doc.internal.pageSize.getHeight()
                // check if image ratio is longer than document ratio
                if (imgProperties.height / imgProperties.width > docHeight / docWidth) {
                    docWidth = (imgProperties.width * docHeight) / imgProperties.height
                } else {
                    docHeight = (imgProperties.height * docWidth) / imgProperties.width
                }
                doc.addImage(img, "PNG", 0, 0, docWidth, docHeight)
                doc.save(`${pipe.name}.pdf`)
            })
        }, 3000)
    }

    const blockCellComponent = (block, index) => {
        const innerComponent = enableLazyLoading ? <LazyLoad
            component={BlockCellComponent}
            block={block}
            pipeId={pipeId}
            usernameFromPath={usernameFromPath}
            reload={reloadCounter}
            onSelectionChanged={onSelectionChanged}
            isAdmin={isAdmin}
        /> : <BlockCellComponent
            block={block}
            pipeId={pipeId}
            usernameFromPath={usernameFromPath}
            reload={reloadCounter}
            onSelectionChanged={onSelectionChanged}
            isAdmin={isAdmin}
        />
        if (enableDragToReorder) {
            return (
                <Draggable key={block.flow_id} draggableId={String(block.flow_id)} index={index}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                ...provided.draggableProps.style,
                                marginBottom: '20px',
                                border: '1px solid black',
                                minHeight: TableBlocks.includes(block.blueprint.type) ? '0px' : '400px',
                                backgroundColor: snapshot.isDragging ? 'whitesmoke' : 'white',
                                minWidth: '96vw'
                            }}
                        >
                            {innerComponent}
                        </div>
                    )}
                </Draggable>
            );
        } else {
            return (
                <div
                    style={{
                        marginBottom: '20px',
                        border: '1px solid black',
                        minHeight: TableBlocks.includes(block.blueprint.type) ? '0px' : '400px',
                        backgroundColor: 'white',
                        minWidth: '96vw'
                    }}
                >
                    {innerComponent}
                </div>
            )
        }
    }

    return (
        <>
            <Box ref={pdfDivRef} sx={{ mt: 7, flexDirection: 'column' }}>
                {/* NOTE: we always include the dropzone, but decide inside each block whether to enable DnD */}
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="blocks" key="dropzone">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {blocks.map((block, index) => (
                                    blockCellComponent(block, index)
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
            {document.getElementById('toolbar-export-button-portal') && createPortal(
                <Tooltip title="Export PDF Report">
                    <IconButton
                        color="inherit"
                        onClick={renderPDF}
                    >
                        <PictureAsPdf />
                    </IconButton>
                </Tooltip>,
                document.getElementById('toolbar-export-button-portal')
            )}
        </>
    )

})


export default DashboardComponent