import {Box, Snackbar, SnackbarContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Error} from "@mui/icons-material";

const ErrorSnackbar = ({error}) => {
    return (
        <Snackbar
            sx={{mt: 7.5}}
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            open={error !== null}
        >
            <SnackbarContent
                style={{backgroundColor: "#8d36d4"}}
                message={
                    error !== null &&
                    <Box sx={{display: 'inline-flex', flexDirection: 'row'}}>
                        <Error/>
                        <Box sx={{ml: 2, display: 'flex', alignItems: "center", justifyContent: "center"}}>
                            <Typography>{error}</Typography>
                        </Box>
                    </Box>
                }
            />
        </Snackbar>
    )
}

export default ErrorSnackbar;