const Route = {
    Home: "/",
    Pipe: "/pipe",
    Template: "/template",
    Pipes: "/pipes",
    Login: "/admin/login",
    Signup: "/signup",
    UserManagement: "/admin/user-management",
    Pacman: "/eastereggs/pacman",
    Demo: "/component/demo",
    Dashboard: "/dashboard",
    AuthCallback: "/auth-callback",
    AuthRedirect: "/authorization/callback",

    // API
    LoginRedirect: "/login-redirect",
    LogoutRedirect: "/logout-redirect"
}

export default Route;
