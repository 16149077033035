
const Logger = {

    init: () => {
    },

    debug: (msg) => {
        console.log(msg)
    },

    info: (msg) => {
        console.log(msg)
    },

    warn: (msg) => {
        console.log(msg)
    },

    error: (msg) => {
        console.log(msg)
    }
}

export default Logger;
