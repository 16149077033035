import PreviewLoadingPlotComponent from "components/PlotComponents/PreviewLoadingPlotComponent";

const PlotNode = ({ nodeId, pipeId, config, usernameFromPath }) => {
    return (
        <PreviewLoadingPlotComponent
            pipeId={pipeId}
            nodeId={nodeId}
            usernameFromPath={usernameFromPath}
            isOnFlowNode={true}
        />
    );
}

export default PlotNode