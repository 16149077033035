import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const EditPipeDialogComponent = ({ open, pipe, onClose, onSaveEdit }) => {
    const [tag, setTag] = useState("")

    useEffect(() => {
        setTag(pipe?.tag)
    }, [pipe])

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Set a tag for the Pipe called "<b>{pipe?.name}</b>"</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p>This tag is used to match files with this template. Tags are case-sensitive and should not be longer than 100 letters.</p>
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Tag"
                    fullWidth
                    variant="outlined"
                    value={tag}
                    onChange={(event) => {
                        setTag(event.target.value)
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => {
                    onSaveEdit(tag)
                    onClose()
                }} variant="contained" >Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditPipeDialogComponent