import { Box, Button, Stack } from "@mui/material";
import { useContext, useState } from "react";
import { ApiContext } from "../helper/ApiContext";
import { UserContext } from "../helper/UserContext";
import UserPermission from "../helper/UserPermission";
import DropzoneComponent from "./DropzoneComponent";
import ErrorSnackbar from "./snackbars/ErrorSnackbar";
import SuccessSnackbar from "./snackbars/SuccessSnackbar";

const UploadDropzoneComponent = ({ onUploadComplete = (file) => { } }) => {

    const { api } = useContext(ApiContext);
    const { user } = useContext(UserContext)
    const [file, setFile] = useState("");
    let [progress, setProgress] = useState(0);
    let [message, setMessage] = useState(null);
    let [error, setError] = useState(null);

    const shouldShowFileUpload = user?.permissions.some((p) => p.name === UserPermission.UPLOAD_FILE)

    const showMessageBar = (message, success) => {
        if (success) {
            setMessage(message);
            setTimeout(() => {
                setMessage(null)
            }, 5000)
        } else {
            setError(message);
            setTimeout(() => {
                setError(null)
            }, 5000)
        }
    }

    const upload = () => {
        let currentFile = file[0];
        setProgress(0);

        api.uploadFile(currentFile, (event) => {
            setProgress(Math.round((90 * event.loaded) / event.total))
        }).then((response) => {
            setProgress(100)
            showMessageBar("File successfully uploaded", true)
            onUploadComplete(currentFile)
        }).catch((error) => {
            setProgress(0)
        });
    }

    return (
        <Box>
            <Stack direction="column" spacing={2}>
                <DropzoneComponent setFile={(file) => {
                    setFile(file)
                    setProgress(0)
                }} />
                <Box textAlign="center">
                    {file.length > 0 && (
                        <div className="progress mb-3">
                            <div
                                className="progress-bar progress-bar-info progress-bar-striped"
                                role="progressbar"
                                style={{ width: progress + "%" }}
                            >
                                {progress}%
                            </div>
                        </div>
                    )}
                    <Button
                        variant="contained"
                        sx={{ ml: "auto" }}
                        disabled={!file || progress > 0}
                        onClick={upload}
                    >
                        Upload
                    </Button>
                </Box>
            </Stack>
            <ErrorSnackbar error={error} />
            <SuccessSnackbar message={message} />
        </Box>
    )
}

export default UploadDropzoneComponent;