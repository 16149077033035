import useWindowDimensions from "helper/WindowDimensions";
import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../helper/ApiContext";
import Logger from "../../helper/Logger";
import LoadingSnackbar from "../snackbars/LoadingSnackbar";

const PreviewLoadingPlotComponent = (
    {
        pipeId,
        nodeId,
        usernameFromPath,
        reload,
        isOnFlowNode = false,
        setPlotDescriptions = () => { }
    }
) => {
    const { api } = useContext(ApiContext);
    const [isLoading, setIsLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        if (isNaN(pipeId) || isNaN(nodeId) || usernameFromPath === undefined) {
            return
        }
        if (isLoading) {
            return
        }
        const controller = new AbortController()
        const signal = controller.signal
        setIsLoading(true)

        api.getPlotPreview({
            pipe_id: pipeId,
            block_id: nodeId,
            username: usernameFromPath,
            sparsify: isOnFlowNode,
            height: (isOnFlowNode) ? 300 : Math.round(height * 0.55),
            signal: signal
        }).then((response) => {
            setIsLoading(false)
            const url = URL.createObjectURL(response.data)
            setImageUrl(url)
            let description = atob(response.headers.get("x-plot-description", ""))
            setPlotDescriptions([description])
        }).catch((error) => {
            setIsLoading(false)
            Logger.error("PreviewLoadingPlotComponent Error " + JSON.stringify(error.name))
        })
        return () => {
            controller.abort()
        }

    }, [reload])

    return (
        <>
            <LoadingSnackbar loading={isLoading} />
            {/* NOTE: we have to do some CSS acrobatics here to account for the 144ppi image coming in. Default ppi is 72, so as to not have the images at 2x size, we scale it down */}
            {imageUrl && <img src={imageUrl} alt="" draggable="false" style={{
                width: imgDimensions.width,
                height: imgDimensions.height,
                transform: 'translateZ(0)',
                transformOrigin: "top left",
                objectFit: "contain",
                display: "block"
            }} onLoad={(img) => {
                setImgDimensions({ width: img.target.naturalWidth / 2, height: img.target.naturalHeight / 2 })
            }} />}
        </>
    )
}

export default PreviewLoadingPlotComponent;