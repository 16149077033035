import {Button, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {AddCircleOutline} from "@mui/icons-material";

export const PipeItemComponent = ({pipe, onClick}) => {
    // TODO: think about a good place to store this URL
    const imageURL = pipe.thumbnail_url ? `${window.REACT_APP_API_ENDPOINT}/${pipe.thumbnail_url}` : undefined

    return (
        <Button variant="outlined" onClick={onClick}>
            <Stack direction="column">
                {imageURL === undefined ?
                    <AddCircleOutline fontSize="large" style={{width: "100%"}} sx={{mt: 2, mb: 2}}/> :
                    <img src={imageURL} width="200" height="150"/>
                }
                <Typography variant="body1" align="center">{pipe.name}</Typography>
            </Stack>
        </Button>
    )
}