import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {useState} from "react";

const SaveSnapshotDialog = ({open, onClose, onSave}) => {
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Save Snapshot</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Your pipeline is saved continuously as you edit. If you save a <b>Snapshot</b> this specific
                    configuration will be frozen and can be shared internally with colleagues or even publicly via the
                    share URL.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Name"
                    fullWidth
                    variant="outlined"
                    onChange={(event) => {
                        setName(event.target.value)
                    }}
                />
                <TextField
                    margin="dense"
                    label="Description"
                    fullWidth
                    variant="outlined"
                    onChange={(event) => {
                        setDescription(event.target.value)
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => {
                    onSave(name, description)
                    onClose()
                }} variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SaveSnapshotDialog