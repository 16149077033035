export const trimNumericalPrecisionTo4Digits = (data) => {
    return data.map((row, index) => {
        Object.keys(row).forEach(key => {
            const value = row[key];
            if (typeof value === 'number') {
                row[key] = value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 4 })
            }
        })
        return row
    })
}