import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField} from "@mui/material";

const DeleteDialogComponent = ({open, objectName, onClose, onDeleteConfirmed}) => {

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Do you really want to delete "<b>{objectName}</b>"?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p>This action cannot be undone. Are you sure you want to delete "{objectName}"?</p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => {
                    onDeleteConfirmed()
                    onClose()
                }} variant="contained" color="error">Delete</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteDialogComponent