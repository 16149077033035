import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemButton, Stack } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import IconButton from "@mui/material/IconButton";
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import { ThemeProvider } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import { CheckboxNames, SkippableBlocks } from "helper/Constants";
import loadTheme from "helper/theme";
import { useIsForeignPipe } from "helper/UrlUtils";
import { useContext, useEffect, useState } from "react";
import Logger from "../../../helper/Logger";
import { UserContext } from "../../../helper/UserContext";
import UserPermission from "../../../helper/UserPermission";
import DefaultPaper from "../../DefaultPaper";
import { BlockIcon } from "../sidebar/BlockIcon";
import { BottomLeftHandle, BottomRightHandle, LeftHandle, RightHandle, TopLeftHandle, TopRightHandle } from "./CustomHandles";
import EditDialog from "./EditDialog";
import IconNode from "./IconNode";
import PlotNode from "./PlotNode";
import SelectedValueNode from "./SelectedValueNode";
import TableNode from "./TableNode";

const CustomFlowNode = ({
    id,
    pipeId,
    usernameFromPath,
    data,
    isReadOnly,
    isValidConnection,
    onDeleteButtonClick,
    onCheckboxClick,
    onValueChanged,
    ...props
}) => {

    const { user } = useContext(UserContext)
    const [shouldShowCloseButton, setShouldShowCloseButton] = useState(false)
    const isForeignPipe = useIsForeignPipe(usernameFromPath)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [blockTitle, setBlockTitle] = useState(data.title)

    useEffect(() => {
        setShouldShowCloseButton(!isReadOnly && user?.permissions.some((p) => p.name === UserPermission.DELETE_NODE))
    }, [user])

    const inputSpecs = data.blueprint.df_specs.filter((c) => c.type === "in")
    const outputSpecs = data.blueprint.df_specs.filter((c) => c.type === "out")
    const [anchorEl, setAnchorEl] = useState(null)
    const isSkippableBlock = SkippableBlocks.includes(data.blueprint.type);
    const checkBoxes = isSkippableBlock ? CheckboxNames : [[CheckboxNames[0]]]
    const [checked, setChecked] = useState({
        [checkBoxes[0]]: data.dashboard_configuration?.view_in_dashboard,
        [checkBoxes[1]]: data.skip
    })
    const open = Boolean(anchorEl)

    const InputHandles = () => {
        if (inputSpecs.length == 0) {
            return <></>
        } else if (inputSpecs.length == 2) {
            return (
                <>
                    <TopLeftHandle isValidConnection={isValidConnection} />
                    <BottomLeftHandle isValidConnection={isValidConnection} />
                </>
            )
        } else {
            return <LeftHandle isValidConnection={isValidConnection} />
        }
    }

    const OutputHandles = () => {
        if (outputSpecs.length == 0) {
            return <></>
        } else if (outputSpecs.length == 2) {
            return (
                <>
                    <TopRightHandle isValidConnection={isValidConnection} />
                    <BottomRightHandle isValidConnection={isValidConnection} />
                </>
            )
        } else {
            return <RightHandle isValidConnection={isValidConnection} />
        }
    }

    // TODO: <finn> add error handling if type is not in componentForType
    // TODO: <finn> find out why we get a reload loop if we turn this into a component here (<InnerComponent/>) ???
    const innerComponent = (data, props) => {
        let mainChild = data.blueprint.children[0] // maybe check first if we have one?
        switch (mainChild.type) {
            case "single_select":
                let values = []
                if (data.blueprint.type === "import" && data.data.value[`0`] !== undefined) {
                    values = [data.data.value[`0`].filename]
                } else {
                    values = data.configuration.elements
                        .filter((element) => (element.type === "single_select" && element.selected_values.length === 1))
                        .map((element) => (element.selected_values[0]))
                }
                return <SelectedValueNode values={values} />
            case "plot":
                return (
                    <PlotNode
                        config={mainChild}
                        nodeId={id}
                        pipeId={pipeId}
                        usernameFromPath={usernameFromPath}
                        {...props}
                    />
                )
            case "table":
                return (
                    <TableNode
                        config={mainChild}
                        nodeId={id}
                        pipeId={pipeId}
                        usernameFromPath={usernameFromPath}
                        {...props}
                    />
                )
            case "icon":
                return <IconNode config={mainChild} {...props} />
            default:
                Logger.error("Unknown node type: " + mainChild.type)
                return <></>
        }
    }
    const onMenuButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const checkboxClick = (id, name, value) => {
        setChecked(prevState => ({
            ...prevState,
            [name]: value
        }));
        onCheckboxClick(id, name, value)
        handleClose()
    }

    return (
        <ThemeProvider theme={loadTheme(checked["Skip"])}>
            <DefaultPaper additionalSx={{ mt: 0 }}>
                <InputHandles />
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={1}>
                        <BlockIcon type={data.blueprint.type} />
                        <Typography variant="body1" sx={{ ml: 1 }}>{blockTitle}</Typography>
                        <Chip label={data.blueprint.title}
                            sx={{
                                fontSize: '0.45rem',
                                height: '50%',
                                boxShadow: 1
                            }} size="small" />
                        {shouldShowCloseButton &&
                            (<IconButton sx={{ ml: "auto", mr: -1, mt: -2 }} onClick={onMenuButtonClick}>
                                <MoreVertIcon />
                            </IconButton>)
                        }
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <ListItemButton onClick={(event) => {
                                event.stopPropagation();
                                setDialogOpen(true);
                                handleClose();
                            }}>
                                <ListItemIcon sx={{ ml: 1 }}>
                                    <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Rename" />
                            </ListItemButton>
                            <ListItemButton onClick={(event) => {
                                event.stopPropagation();
                                onDeleteButtonClick(id);
                                handleClose();
                            }}>
                                <ListItemIcon sx={{ ml: 1 }}>
                                    <DeleteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" />
                            </ListItemButton>

                            {checkBoxes.map((item, index) => (
                                <ListItem key={index} onClick={(event) => {
                                    event.stopPropagation();
                                }}>
                                    <ListItemIcon>
                                        <Checkbox
                                            onChange={(event) => checkboxClick(id, event.target.name, event.target.checked)}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                            }}
                                            name={item}
                                            checked={checked[item]}
                                            disabled={isForeignPipe()}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={item} />
                                </ListItem>
                            ))}

                        </Menu>
                    </Stack>
                    {innerComponent(data, props)}
                </Stack>
                <EditDialog open={dialogOpen} setOpen={setDialogOpen} blockTitle={blockTitle} blockDescription={data.description} setBlockTitle={setBlockTitle} username={usernameFromPath} pipeId={pipeId} nodeId={id} />
                <OutputHandles />
            </DefaultPaper>
        </ThemeProvider>
    )
}

export default CustomFlowNode